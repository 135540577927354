import React, { Component } from 'react';
import { AppContext } from "../App/app.context";
import './Pause.scss';

export default class Pause extends Component {
	public static contextType = AppContext;
	public render() {
		let appContext = this.context;
		return (
			<div
				className={"Pause"}
				style={{
					height: window.innerHeight,
					width: window.innerWidth
				}}
			>
				<div className={"pausedTitle"}>PAUSED</div>
				<div className={"continueButton"} onClick={() => appContext.pause()}>CONTINUE</div>
			</div>
		);
	}
}