import React, { Component } from 'react';
import { AppContext, IAppContext } from "../App/app.context";
import './GameOver.scss';

export default class GameOver extends Component {
	public static contextType = AppContext;
	public render() {
		let appContext: IAppContext = this.context;
		return (
			<div
				className={"GameOver"}
				style={{
					height: window.innerHeight,
					width: window.innerWidth
				}}
			>
				<div className={"gameOverTitle"}>SAMMY DIED!</div>
				<div className={"gameOverButtons"}>
					<div className={"gameOverButton"} onClick={() => appContext.start()}>RESTART</div>
					<div className={"gameOverButton"} onClick={() => appContext.goToMenu()}>MENU</div>
				</div>
			</div>
		);
	}
}