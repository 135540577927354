import React, { Component } from 'react';
import { AppContext, IAppContext } from "../App/app.context";
import Food from "../Food/Food";
import Snake from "../Snake/Snake";
import './Board.scss';

export interface IBoardProps {

}

export interface IBoardState {

}

export default class Board extends Component<IBoardProps, IBoardState> {
	public static contextType = AppContext;
	constructor(props: IBoardProps, state: IBoardState) {
		super(props);
		this.state = {

		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public componentDidMount() {

	}

	public render() {
		this.appContext = this.context;
		let { width, height, gameMode, blockSize } = this.appContext;
		return (
			<div className="Board"
			>
				<div
					style={{
						height,
						width,
						backgroundColor: "#e3ff92",
						position: "relative",
						border: gameMode === "Snake1" ? (blockSize + "px solid #68773b") : "none"
					}}
				>
					<Snake />
					<Food />
				</div>
			</div>
		);
	}
}