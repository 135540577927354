import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from 'react';
import Board from "../Board/Board";
import GameOver from "../GameOver/GameOver";
import { IBeforeInstallPromptEvent } from "../helpers/beforeinstall";
import Menu from "../Menu/Menu";
import Pause from "../Pause/Pause";
import Score from "../Score/Score";
import AppProvider, { AppContext, IAppContext } from "./app.context";
import './App.scss';
import icon from "./icon512.png";

export interface IAppProps {

}

export interface IAppState {
	prompt?: IBeforeInstallPromptEvent | Event;
}

export class AppHome extends Component<IAppProps, IAppState> {
	public static contextType = AppContext;

	constructor(props: IAppProps, state: IAppState) {
		super(props);
		this.state = {

		}
	}

	public componentDidMount() {
		window.addEventListener('appinstalled', (evt) => {
			console.log('a2hs', 'installed');
		});
		window.addEventListener('beforeinstallprompt', (prompt) => {
			console.log("BEFOREINSTALLPROMPT REGISTER");
			if (!/Android/i.test(navigator.userAgent)) {
				this.setState({ prompt });
			}
		});
	}

	public render() {
		let appContext: IAppContext = this.context;
		return (
			appContext.menu
				? <Menu>
					{
						this.state.prompt
							? <div className={"installPrompt"}>
								<div className={"installDetailsContainer"}>
									<img className={"installIcon"} src={icon} />
									<div className={"installDetails"}>
										<div className={"installTitle"}>Sammy the Snake</div>
										<div className={"installURL"}>https://snake.inprod.dev</div>
									</div>
									<div className={"installClose"} onClick={() => this.setState({ prompt: undefined })}><CloseIcon /></div>
								</div>
								<div className={"installButtonContainer"}>
									<div className={"installButton"} onClick={() => this.installApp()}>ADD TO HOME SCREEN</div>
								</div>
							</div>
							: null
					}
				</Menu>
				: <div className="App" style={{ height: window.innerHeight }}>
					<Score />
					<Board />
					{
						appContext.paused &&
						<Pause />
					}
					{
						appContext.gameOver &&
						<GameOver />
					}
				</div>

		);
	}

	private async installApp() {
		const { prompt } = this.state;
		(prompt! as IBeforeInstallPromptEvent).prompt();
		const result = await (prompt! as IBeforeInstallPromptEvent).userChoice;
		if (result.outcome === 'accepted') {
			console.log('User accepted the A2HS prompt');
		} else {
			console.log('User dismissed the A2HS prompt');
		}
		this.setState({ prompt: undefined });
	}
}

export default class App extends React.Component<IAppProps, IAppState> {
	public render() {
		return (
			<AppProvider>
				<AppHome />
			</AppProvider>
		);
	}
}