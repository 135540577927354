import * as React from "react";
import { FoodPosition } from "../Food/FoodPosition";
import { SnakePart } from "../Snake/SnakePart";

export enum Direction {
	Up,
	Right,
	Down,
	Left
}

export interface IAppProviderState {
	width: number;
	height: number;
	columns: number;
	rows: number;
	blockSize: number;
	highScore: number;
	menu: boolean;
	direction?: Direction;
	gameOver: boolean;
	startGame: boolean;
	foodPosition: FoodPosition;
	score: number;
	snake: SnakePart[];
	speed: number;
	gameMode: "Snake1" | "Snake2";
	xDown: number | null;
	yDown: number | null;
	paused: boolean;
	hasTouch: boolean;
}

export interface IAppContext extends IAppProviderState {
	start(): void;
	goToMenu(): void;
	pause(): void;
	setRules(rules: "Snake1" | "Snake2"): void;
}

export const AppContext = React.createContext<IAppContext>(
	{
		highScore: 0,
		width: 0,
		height: 0,
		columns: 0,
		rows: 0,
		blockSize: 0,
		gameOver: false,
		startGame: false,
		foodPosition: {} as FoodPosition,
		score: 0,
		snake: [],
		speed: 0,
		gameMode: "Snake2",
		xDown: null,
		yDown: null,
		menu: false,
		paused: false,
		hasTouch: false,
		start: () => null,
		goToMenu: () => null,
		pause: () => null,
		setRules: () => null
	}
);


export interface IAppProviderProps {

}

export default class AppProvider extends React.Component<IAppProviderProps, IAppProviderState> {
	constructor(props: IAppProviderProps, state: IAppProviderState) {
		super(props);
		let highScore = 0;
		let highScoreString = localStorage.getItem("highScore");
		if (highScoreString) highScore = parseInt(highScoreString);
		let gameMode = localStorage.getItem("gameMode") as "Snake1" | "Snake2";
		if (!gameMode) gameMode = "Snake2";

		let columns = 50;
		let rows = 50;
		let width = 0;
		let height = 0;
		let blockSize = 0;
		if (window.innerWidth > window.innerHeight) {
			height = window.innerHeight - 150;
			blockSize = height / rows;
			width = columns * blockSize;
		} else {
			width = window.innerWidth - 20;
			blockSize = width / columns;
			height = rows * blockSize;
		}
		this.state = {
			menu: true,
			highScore,
			width,
			height,
			columns,
			rows,
			blockSize,
			startGame: false,
			gameOver: false,
			score: 0,
			gameMode,
			snake: this.getStartSnake(rows, columns),
			speed: 100,
			foodPosition: {} as FoodPosition,
			xDown: null,
			yDown: null,
			paused: false,
			hasTouch: false,
		}
	}

	private setHasTouch = this.hasTouch.bind(this);
	private setarrow = this.setArrowMove.bind(this);
	private touchStart = this.setTouchStart.bind(this);
	private touchMove = this.setTouchMove.bind(this);
	private timer: NodeJS.Timeout = setInterval(() => null, 1);

	public componentDidMount(): void {
		window.addEventListener('touchstart', this.setHasTouch, false);
	}

	public componentWillUnmount() {
	}

	public render() {
		return (
			<AppContext.Provider value={{
				...this.state,
				start: () => this.startGame(),
				goToMenu: () => this.goToMenu(),
				pause: () => this.pause(),
				setRules: (gameMode) => this.setRules(gameMode)
			}}>
				{this.props.children}
			</AppContext.Provider>
		);
	}

	private hasTouch() {
		this.setState({
			hasTouch: true
		});
		console.log("TOUCH");

		window.removeEventListener('touchstart', this.setHasTouch);
	}

	private goToMenu() {
		document.removeEventListener("keydown", this.setarrow, false);
		document.removeEventListener('touchstart', this.touchStart, false);
		document.removeEventListener("touchmove", this.touchMove);
		clearInterval(this.timer);
		this.setState({
			menu: true,
			direction: undefined,
			gameOver: true,
			startGame: false,
			paused: false
		});
	}

	private pause() {
		let paused = !this.state.paused;
		this.setState({
			paused
		}, () => {
			if (paused) {
				clearInterval(this.timer);
			} else {
				this.move();
			}
		});
	}

	private setRules(gameMode: "Snake1" | "Snake2") {
		localStorage.setItem("gameMode", gameMode);
		this.setState({
			gameMode
		});
	}

	private startGame() {
		document.addEventListener("keydown", this.setarrow, false);
		document.addEventListener('touchstart', this.touchStart, false);
		document.addEventListener("touchmove", this.touchMove, { passive: false });
		let { height, width, gameMode, blockSize, rows, columns } = this.state;
		if (gameMode === "Snake1") {
			if (window.innerWidth > window.innerHeight) {
				height = (window.innerHeight - 150) - (2 * blockSize);
				blockSize = height / rows;
				width = columns * blockSize;
			} else {
				width = (window.innerWidth - 20) - (2 * blockSize);
				blockSize = width / columns;
				height = rows * blockSize;
			}
		}
		this.setState({
			gameOver: false,
			startGame: false,
			paused: false,
			direction: undefined,
			score: 0,
			menu: false,
			height,
			width,
			blockSize,
			snake: this.getStartSnake(this.state.rows, this.state.columns)
		}, () => {
			this.setFood();
			this.move();
		});
	}

	private getStartSnake(rows: number, columns: number) {
		let startRow = Math.floor(rows / 2);
		let startCol = Math.floor(columns / 2);
		return [
			{ row: startRow, column: startCol, direction: Direction.Right },
			{ row: startRow, column: startCol - 1, direction: Direction.Right },
			{ row: startRow, column: startCol - 2, direction: Direction.Right }
		];
	}

	private setArrowMove(event: KeyboardEvent) {
		if (event.key !== "ArrowUp" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowLeft" &&
			event.key !== "ArrowRight") {
			return;
		}
		event.preventDefault();
		this.updateDirection(event.key);
	}

	private updateDirection(key: string) {
		const { direction, snake, startGame } = this.state;
		let newDirection: Direction | undefined = undefined;
		switch (key) {
			case "ArrowUp":
				if (direction !== Direction.Down)
					newDirection = Direction.Up;
				break;
			case "ArrowDown":
				if (direction !== Direction.Up)
					newDirection = Direction.Down;
				break;
			case "ArrowLeft":
				if (direction !== Direction.Right)
					newDirection = Direction.Left;
				break;
			case "ArrowRight":
				if (direction !== Direction.Left)
					newDirection = Direction.Right;
				break;

			default:
				break;
		}
		if (newDirection !== undefined && snake[0].direction === direction) {
			this.setState({ direction: newDirection });
		}
		if (direction === undefined && newDirection !== Direction.Left) {
			this.setState({
				direction: newDirection,
				startGame: true
			});
		}
	}

	private setTouchStart(event: TouchEvent) {
		if (!this.state.gameOver)
			this.setState({
				xDown: event.touches[0].clientX,
				yDown: event.touches[0].clientY
			});
	}

	private setTouchMove(event: TouchEvent) {
		event.preventDefault();
		if (!this.state.gameOver) {
			let { xDown, yDown } = this.state;
			if (!xDown || !yDown) {
				return;
			}

			const xUp = event.touches[0].clientX;
			const yUp = event.touches[0].clientY;

			const xDelta = xDown - xUp;
			const yDelta = yDown - yUp;

			if (Math.abs(xDelta) > Math.abs(yDelta)) {
				if (xDelta > 0) {
					this.updateDirection("ArrowLeft");
				} else {
					this.updateDirection("ArrowRight");
				}
			} else {
				if (yDelta > 0) {
					this.updateDirection("ArrowUp");
				} else {
					this.updateDirection("ArrowDown");
				}
			}
		}
	}

	private died() {
		document.removeEventListener("keydown", this.setarrow, false);
		document.removeEventListener('touchstart', this.touchStart, false);
		document.removeEventListener("touchmove", this.touchMove);
		clearInterval(this.timer);
		let { highScore } = this.state;
		if (this.state.score > highScore) {
			highScore = this.state.score;
			localStorage.setItem("highScore", highScore.toString());
		}
		this.setState({
			gameOver: true,
			highScore,
			direction: undefined
		});
	}

	private setFood() {
		let { rows, columns, snake } = this.state;
		let row = Math.floor(Math.random() * rows);
		let column = Math.floor(Math.random() * columns);
		while (snake.some(part => part.row === row && part.column === column)) {
			row = Math.floor(Math.random() * rows);
			column = Math.floor(Math.random() * columns);
		}
		this.setState({
			foodPosition: {
				row,
				column
			}
		});
	}

	private growSnake() {
		let { snake, score } = this.state;
		let tail = snake[snake.length - 1];
		let row = tail.row;
		let column = tail.column;
		let direction = tail.direction;
		switch (direction) {
			case Direction.Up:
				row++;
				break;
			case Direction.Right:
				column--;
				break;
			case Direction.Down:
				row--;
				break;
			case Direction.Left:
				column++;
				break;
			default:
				break;
		}
		score++;
		snake.push({ row, column, direction });
		this.setState({
			snake,
			score
		}, () => this.setFood());
	}

	private moveSnake() {
		let { snake, direction, gameMode } = this.state;
		let row = snake[0].row;
		let column = snake[0].column;
		switch (direction) {
			case Direction.Up:
				row--;
				break;
			case Direction.Right:
				column++;
				break;
			case Direction.Down:
				row++;
				break;
			case Direction.Left:
				column--;
				break;
			default:
				break;
		}
		let head: SnakePart = { row, column, direction };
		head = this.checkTeleport(head);

		let newSnake = [head];
		for (let i = 1; i < snake.length; i++) {
			newSnake.push(snake[i - 1]);
		}

		this.setState({
			snake: newSnake
		});
	}

	private checkTeleport(head: SnakePart) {
		const { rows, columns, gameMode } = this.state;
		if (head.column > columns - 1) {
			gameMode === "Snake2" ? head.column = 0 : this.died();
		}
		if (head.column < 0) {
			gameMode === "Snake2" ? head.column = columns - 1 : this.died();
		}
		if (head.row > rows - 1) {
			gameMode === "Snake2" ? head.row = 0 : this.died();
		}
		if (head.row < 0) {
			gameMode === "Snake2" ? head.row = rows - 1 : this.died();
		}
		return head;
	}

	private checkGameOver() {
		const head = this.state.snake[0];
		let dead = this.state.snake.some((part, index) =>
			index !== 0 && head.row === part.row && head.column === part.column
		);
		if (dead) this.died();
	}

	private eatFood() {
		let { foodPosition, snake } = this.state;
		let head = snake[0];
		if (head.row === foodPosition.row && head.column === foodPosition.column) {
			this.growSnake();
		}
	}

	private move() {
		this.timer = setInterval(() => {
			const { gameOver, startGame } = this.state;
			if (!gameOver && startGame) {
				this.moveSnake();
				this.checkGameOver();
				this.eatFood();
			}
		}, this.state.speed);
	}
}
