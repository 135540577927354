import React, { Component } from 'react';
import { AppContext, Direction, IAppContext } from "../App/app.context";
import './Snake.scss';


export interface ISnakeProps {

}

export interface ISnakeState {
}

export default class Snake extends Component<ISnakeProps, ISnakeState> {
	public static contextType = AppContext;
	constructor(props: ISnakeProps, state: ISnakeState) {
		super(props);
		this.state = {
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public componentDidMount() {
	}

	public render() {
		this.appContext = this.context;
		const { blockSize, snake } = this.appContext;
		return (
			snake.map((item, index) =>
				<div
					key={index}
					style={{
						height: blockSize,
						width: blockSize,
						position: "absolute",
						top: blockSize * item.row,
						left: blockSize * item.column,
						zIndex: index === 0 ? 2 : 1,
						display: "grid",
						justifyContent: "center",
						alignContent: "center"
					}}
				>
					<div
						style={{
							height: blockSize,
							width: blockSize,
							backgroundColor: index === 0 ? "#00000040" : "#68773b",
							borderRadius: this.getBorderRadius(index)
						}}
					/>
				</div>
			)
		);
	}

	private getBorderRadius(index: number) {
		let { snake } = this.appContext;
		let part = snake[index];

		if (index === 0) {
			if (part.direction === Direction.Up) {
				return "50% 50% 0 0";
			}
			if (part.direction === Direction.Down) {
				return "0 0 50% 50%";
			}
			if (part.direction === Direction.Left) {
				return "50% 0 0 50%";
			}
			if (part.direction === Direction.Right) {
				return "0 50% 50% 0";
			}
		}

		let parent = snake[index - 1];
		if (index === snake.length - 1) {
			if (parent.direction === Direction.Up) {
				return "0 0 50% 50%";
			}
			if (parent.direction === Direction.Down) {
				return "50% 50% 0 0";
			}
			if (parent.direction === Direction.Left) {
				return "0 50% 50% 0";
			}
			if (parent.direction === Direction.Right) {
				return "50% 0 0 50%";
			}
		}

		if (parent.direction !== part.direction) {
			if (parent.direction === Direction.Up) {
				if (part.direction === Direction.Left) {
					return "0 0 0 50%";
				}
				if (part.direction === Direction.Right) {
					return "0 0 50% 0";
				}
			}
			if (parent.direction === Direction.Down) {
				if (part.direction === Direction.Left) {
					return "50% 0 0 0";
				}
				if (part.direction === Direction.Right) {
					return "0 50% 0 0";
				}
			}
			if (parent.direction === Direction.Left) {
				if (part.direction === Direction.Up) {
					return "0 50% 0 0";
				}
				if (part.direction === Direction.Down) {
					return "0 0 50% 0";
				}
			}
			if (parent.direction === Direction.Right) {
				if (part.direction === Direction.Up) {
					return "50% 0 0 0";
				}
				if (part.direction === Direction.Down) {
					return "0 0 0 50%";
				}
			}
		}
		return "0 0 0 0";
	}
}