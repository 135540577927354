import React, { Component } from 'react';
import { AppContext, IAppContext } from "../App/app.context";
import './Food.scss';

export interface IFoodProps {

}

export interface IFoodState {

}

export default class Food extends Component<IFoodProps, IFoodState> {
	public static contextType = AppContext;
	constructor(props: IFoodProps, state: IFoodState) {
		super(props);
		this.state = {

		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public componentDidMount() {

	}

	public render() {
		this.appContext = this.context;
		const { blockSize, foodPosition } = this.appContext;
		return (
			<div
				style={{
					height: blockSize || 0,
					width: blockSize || 0,
					position: "absolute",
					top: blockSize * foodPosition.row || 0,
					left: blockSize * foodPosition.column || 0,
					zIndex: 0,
					display: "grid",
					justifyContent: "center",
					alignContent: "center"
				}}
			>
				<div
					style={{
						height: blockSize / 1.5 || 0,
						width: blockSize / 1.5 || 0,
						backgroundColor: "#68773b"
					}}
				/>
			</div>
		);
	}
}