import React, { Component } from 'react';
import { AppContext, IAppContext } from "../App/app.context";
import './Score.scss';

export interface IScoreProps {

}

export interface IScoreState {
}

export default class Score extends Component<IScoreProps, IScoreState> {
	public static contextType = AppContext;
	constructor(props: IScoreProps, state: IScoreState) {
		super(props);
		this.state = {
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public componentDidMount() {

	}

	public render() {
		this.appContext = this.context;
		const { score } = this.appContext;
		return (
			<div className={"Score"}>
				<div className={"NavButtons"}>
					<div className={"navButton"} onClick={() => this.appContext.goToMenu()}>MENU</div>
					<div className={"navButton"} onClick={() => this.appContext.pause()}>PAUSE</div>
				</div>
				<div>SCORE: {score}</div>
			</div>
		);
	}
}