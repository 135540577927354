import React, { Component } from 'react';
import version from "../../package.json";
import { AppContext, IAppContext } from "../App/app.context";
import './Menu.scss';

export interface IMenuProps {

}

export interface IMenuState {
	showInstructions: boolean;
}

export default class Menu extends Component<IMenuProps, IMenuState> {
	public static contextType = AppContext;
	constructor(props: IMenuProps, state: IMenuState) {
		super(props);
		this.state = {
			showInstructions: false
		}
		this.appContext = this.context;
	}

	private appContext: IAppContext;

	public componentDidMount() {

	}

	public render() {
		this.appContext = this.context;
		const { highScore, gameMode, hasTouch } = this.appContext;

		return (
			<div className={"container"}
				style={{
					height: window.innerHeight
				}}
			>
				{
					this.state.showInstructions
						? <div
							className={"Menu"}
							style={{ maxHeight: window.innerHeight }}
						>
							<div
								style={{ fontSize: "0.75rem", padding: "1rem" }}
							>
								{
									hasTouch
										? <p>Guide Sammy around the game board by swiping in the direction you want him to turn.</p>
										: <p>Use the arrow keys to guide Sammy around the game board.</p>
								}
								<p>Eat food to grow as big as you can and try not to eat yourself!</p>
								<p>Select from 2 game modes, classic or Nokia 3310.  In classic mode you have to avoid the walls, whereas in 3310 Sammy will teleport to the other side!</p>
								<div
									onClick={() => this.setState({ showInstructions: false })}
									className={"menuButton"}
								>
									BACK
							</div>
							</div>
						</div>
						: <div
							className={"Menu"}
						>
							<h1 className={"title"}>SAMMY THE SNAKE</h1>
							<div
								onClick={() => this.appContext.start()}
								className={"menuButton"}
							>
								PLAY
							</div>
							<div>
								<div className={"gameModeTitle"}>GAME MODE</div>
								<div className={"gameModes"}>
									<div
										className={"gameModeButton"}
										onClick={() => this.appContext.setRules("Snake1")}
										style={{
											borderBottom: gameMode === "Snake1" ? "3px solid #e3ff9280" : "none"
										}}
									>
										CLASSIC
									</div>
									<div
										className={"gameModeButton"}
										onClick={() => this.appContext.setRules("Snake2")}
										style={{
											borderBottom: gameMode === "Snake2" ? "3px solid #e3ff9280" : "none"
										}}
									>
										3310
									</div>
								</div>
							</div>
							<div
								onClick={() => this.setState({ showInstructions: true })}
								className={"menuButton"}
							>
								INSTRUCTIONS
							</div>
							<div className={"highScore"}>HIGH SCORE: {highScore}</div>
							<div style={{ fontSize: "0.7rem" }}>
								<div>an Insignificant Production</div>
								<div style={{ fontSize: "0.7rem" }}>{version.version}</div>
							</div>
						</div>
				}
				{this.props.children}
			</div>
		);
	}
}