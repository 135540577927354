import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	typography: {
		useNextVariants: true,
	}
});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<CssBaseline>
			<App />
		</CssBaseline>
	</MuiThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
